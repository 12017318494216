// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-booking-and-prices-js": () => import("./../src/pages/booking-and-prices.js" /* webpackChunkName: "component---src-pages-booking-and-prices-js" */),
  "component---src-pages-booking-js": () => import("./../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-thanks-js": () => import("./../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-all-dive-sites-js": () => import("./../src/templates/all-dive-sites.js" /* webpackChunkName: "component---src-templates-all-dive-sites-js" */),
  "component---src-templates-dalaroe-dykpark-js": () => import("./../src/templates/dalaroe-dykpark.js" /* webpackChunkName: "component---src-templates-dalaroe-dykpark-js" */),
  "component---src-templates-dive-site-js": () => import("./../src/templates/dive-site.js" /* webpackChunkName: "component---src-templates-dive-site-js" */),
  "component---src-templates-nynaeshamn-js": () => import("./../src/templates/nynaeshamn.js" /* webpackChunkName: "component---src-templates-nynaeshamn-js" */),
  "component---src-templates-other-dive-sites-js": () => import("./../src/templates/other-dive-sites.js" /* webpackChunkName: "component---src-templates-other-dive-sites-js" */)
}

